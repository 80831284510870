.SMAListHead.sticky {
  height: 0;
  span,
  div,
  button {
    display: none;
  }
}

.SMAListFilter.sticky,
.SMAListSetting.sticky {
  display: none;
}

.SMAListTableContainer.sticky {
  margin-top: -24px;
  max-height: calc(98vh - 371px + 78px) !important;
}

.SMAListTableHead {
  tr:nth-child(1) th:first-child,
  tr:nth-child(2) th:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
  }

  tr:nth-child(1) th:nth-child(2),
  tr:nth-child(2) th:nth-child(2) {
    position: sticky;
    left: 100px !important;
    z-index: 5;
  }

  tr:nth-child(2) th:nth-child(3) {
    position: sticky;
    left: 350px !important;
    z-index: 5;
  }

  tr th {
    top: 0;
  }
}

.SMAListTableHead.sticky {
  tr {
    background-color: #F8F8FD !important;
  }
}

.SMAListTableHead.scroll {
  tr {
    background-color: #F8F8FD !important;
  }
}

.SMAListTableHead.stickyFirstCell {
  tr:nth-child(1) th:nth-child(1),
  tr:nth-child(1) th:nth-child(2) {
    background-color: #F8F8FD !important;
  }

  tr:nth-child(2) th:nth-child(1),
  tr:nth-child(2) th:nth-child(2),
  tr:nth-child(2) th:nth-child(3) {
    background-color: #F8F8FD !important;
  }
}

.SMAListTableBody {
  tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  tr td:nth-child(2) {
    position: sticky;
    left: 100px !important;
    z-index: 2;
  }
  tr td:nth-child(3) {
    position: sticky;
    left: 350px !important;
    z-index: 2;
  }
}

.SMAListTableBody.sticky {
  tr td:first-child,
  tr td:nth-child(2),
  tr td:nth-child(3) {
    background-color: inherit !important;
  }
}
